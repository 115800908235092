import styled from 'styled-components';

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 9px;
  /* max-height: 73px; */
  overflow: auto;
  padding: 4px; // added
  height: 262px; // added

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    strong {
      margin-right: 7px;
      margin-left: 3px;
      font-size: 12px;
    }

    span {
      font-size: 12px;
      margin: 4px 0px; // added
    }

    img {
      width: 21px;
      margin: 2px;
      margin-left: 0px;
    }
  }
`;
