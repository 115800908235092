import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import { InternalUserObject } from '../../types';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import trash from '../../../../assets/images/icons/trash.svg';

interface ListInterface {
  filteredList: InternalUserObject[];
  handleOpenDeleteModal: (cupomObject: InternalUserObject) => void;
}

export default function List({
  filteredList,
  handleOpenDeleteModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((user) => {
          return (
            <Col key={user.auth_id}>
              <OpacityAnimation delay={0.1}>
                <Card>
                  <div className="info">
                    <div className="card-title">
                      <strong>{user.email}</strong>
                    </div>
                  </div>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => handleOpenDeleteModal(user)}
                    >
                      <img src={trash} alt="" title="Remover usuário" />
                    </button>
                  </div>
                </Card>
              </OpacityAnimation>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
