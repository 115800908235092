import PropTypes from 'prop-types';
import FileInput from '../../../components/FileInput';
import MyModal from '../../../components/Modal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface PaymentVoucherUploadModal {
  paymentVoucherUploadModalShow: boolean;
  paymentVoucherFileName: string;
  handlePaymentVoucherFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handlePaymentVoucherFileSubmit: () => void;
  setPaymentVoucherUploadModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function PaymentVoucherUploadModal({
  paymentVoucherUploadModalShow,
  paymentVoucherFileName,
  handlePaymentVoucherFileUpload,
  handlePaymentVoucherFileSubmit,
  setPaymentVoucherUploadModalShow,
}: PaymentVoucherUploadModal) {
  return (
    <MyModal
      show={paymentVoucherUploadModalShow}
      title="Upload de comprovante de pagamento"
      centeredBody
      modalBody={(
        <FileInput
          buttonLabel="Importar arquivo"
          fileNameChoosed={paymentVoucherFileName}
          isSubmitButtonDisabled={!paymentVoucherFileName}
          onFileUpload={handlePaymentVoucherFileUpload}
          onSubmitFile={handlePaymentVoucherFileSubmit}
          acceptedFiles=".pdf"
        />
      )}
      closeButtonLabel="Fechar"
      onClose={() => setPaymentVoucherUploadModalShow(false)}
      type="info"
    />
  );
}

PaymentVoucherUploadModal.propTypes = {
  paymentVoucherUploadModalShow: PropTypes.bool.isRequired,
  paymentVoucherFileName: PropTypes.string.isRequired,
  handlePaymentVoucherFileUpload: PropTypes.func.isRequired,
  handlePaymentVoucherFileSubmit: PropTypes.func.isRequired,
  setPaymentVoucherUploadModalShow: PropTypes.func.isRequired,
};
