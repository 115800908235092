import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface AuthServiceInterface {
  reqBody?: ReqBodyType;
  token: string;
}

class AuthService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  auth = async({
    reqBody,
    token,
  }: AuthServiceInterface) => {
    return this.httpClient.post({
      path: '/login',
      reqBody,
      token
    });
  };

  changePassword = async({
    reqBody,
    token,
  }: AuthServiceInterface) => {
    return this.httpClient.post({
      path: '/update-password',
      reqBody,
      token,
    });
  };

  resetPassword = async({
    reqBody,
    token,
  }: AuthServiceInterface) => {
    return this.httpClient.post({
      path: '/reset-password',
      reqBody,
      token,
    });
  };
}

export default new AuthService();
