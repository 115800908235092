import { Link } from 'react-router-dom';
import chartPie from '../../../assets/images/icons/chartPie.svg';
import faq from '../../../assets/images/icons/faq.svg';
import lock from '../../../assets/images/icons/lock.svg';
import user from '../../../assets/images/icons/user.svg';
import userTie from '../../../assets/images/icons/userTie.svg';
import { ActiveItem, Container, MenuItem } from '../styles';

interface NavBarInterface {
  active: string;
}

export default function ReadOnlyNavbar({ active }: NavBarInterface) {
  return (
    <Container>

      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/dashboard">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/dashboard">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

      {active === 'Users'
        ? (
          <ActiveItem>
            <Link to="/collaborators">
              <MenuItem>
                <img src={user} alt="users" />
                Beneficiários
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/collaborators">
            <MenuItem>
              <img src={user} alt="users" />
              Beneficiários
            </MenuItem>
          </Link>
        )}

      {active === 'SystemUsers'
        ? (
          <ActiveItem>
            <Link to="/systemUsers">
              <MenuItem>
                <img src={userTie} alt="user" />
                Usuários (sistema)
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/systemUsers">
            <MenuItem>
              <img src={userTie} alt="user" />
              Usuários (sistema)
            </MenuItem>
          </Link>
        )}

      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}

      {active === 'FAQ'
        ? (
          <ActiveItem>
            <Link to="/faq">
              <MenuItem>
                <img src={faq} alt="faq" />
                FAQ
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/faq">
            <MenuItem>
              <img src={faq} alt="faq" />
              FAQ
            </MenuItem>
          </Link>
        )}

    </Container>
  );
}
