/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UserBeingActivateInterface } from '../../interfaces/UserBeingActivateInterface';
import useApiCall from '../../../../hooks/useApiCall';

interface UseActivateCollaboratorInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
}

export default function useActivateCollaborator({
  loadUsers,
  currentPage,
}: UseActivateCollaboratorInterface) {
  const [isActivating, setIsActivating] = useState(false);
  const [modalActivateShow, setModalActivateShow] = useState(false);
  const [userBeingActivate, setUserBeingActivate] = useState<UserBeingActivateInterface>({});

  const { apiCall } = useApiCall();

  const activateCollaborator = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.updateUserStatus,
      reqBody: JSON.stringify({
        id: userBeingActivate.id,
        status: 'reativar',
      }),
      onStartLoad: () => {
        setModalActivateShow(false);
        setIsActivating(true);
      },
      onEndLoad: () => setIsActivating(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível reativar o benefício do colaborador. Por favor, tente novamente');
          setIsActivating(false);
          return;
        }
        toast.success('Beneficio do colaborador reativado com sucesso!');
        loadUsers(currentPage);
      },
      catchMessage: 'Não foi possível reativar o benefício do colaborador. Por favor, tente novamente',
    });
  }, [apiCall, userBeingActivate.id, loadUsers, currentPage]);

  return {
    isActivating,
    modalActivateShow,
    setModalActivateShow,
    userBeingActivate,
    setUserBeingActivate,
    activateCollaborator,
  };
}
