import { ThemeProvider } from 'styled-components';
import GlobalStyles from './assets/styles/global';
import AppRoutes from './routes/AppRoutes';
import LoginRoute from './routes/LoginRoute';

import {
  Container, PageContainer, RouteContainer, ThemeRadioButtonsContainer,
} from './components/App/styles';
import ThemeRadioButton from './components/ThemeRadioButton';

import lightTheme from './assets/styles/themes/default';
import darkTheme from './assets/styles/themes/dark';

import sun from './assets/images/icons/sun.svg';
import moon from './assets/images/icons/moon.svg';

import useLocalState from './hooks/useLocalState';
import { useAppContext } from './contexts/auth';
import ReadOnlyRoutes from './routes/ReadOnlyRoutes';

export default function Routes() {
  const [theme, setTheme] = useLocalState('theme', lightTheme);
  const { signed, user } = useAppContext();
  const { readOnly } = user;

  return signed
    ? (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Container>
          <PageContainer>
            <RouteContainer>
              <div className="darkMode">
                <ThemeRadioButtonsContainer>
                  <ThemeRadioButton
                    onClick={() => setTheme(lightTheme)}
                    selected={theme.colors.background != '#121212'}
                  >
                    <img src={sun} alt="lightTheme" />
                  </ThemeRadioButton>

                  <ThemeRadioButton
                    onClick={() => setTheme(darkTheme)}
                    selected={theme.colors.background == '#121212'}
                  >
                    <img src={moon} alt="darkTheme" />
                  </ThemeRadioButton>
                </ThemeRadioButtonsContainer>
              </div>

              {readOnly ? <ReadOnlyRoutes /> : <AppRoutes />}

            </RouteContainer>
          </PageContainer>
        </Container>
      </ThemeProvider>
    )
    : (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PageContainer>
          <div className="darkModeAtLoginPage">
            <ThemeRadioButtonsContainer>
              <ThemeRadioButton
                onClick={() => setTheme(lightTheme)}
                selected={theme.colors.background != '#121212'}
              >
                <img src={sun} alt="lightTheme" />
              </ThemeRadioButton>

              <ThemeRadioButton
                onClick={() => setTheme(darkTheme)}
                selected={theme.colors.background == '#121212'}
              >
                <img src={moon} alt="darkTheme" />
              </ThemeRadioButton>
            </ThemeRadioButtonsContainer>
          </div>
        </PageContainer>
        <LoginRoute />
      </ThemeProvider>
    );
}
