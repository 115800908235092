import { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/auth';
import formatDate from '../../../utils/formatDate';
import useLoadFinancial from './functions/useLoadFinancial';
import useVoucherUpload from './functions/useVoucherUpload';

type MonthsNumbersType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export default function useFinancial() {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentMonth = currentDate.split('-')[1];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const { user } = useAppContext();
  const formattedStartDate = formatDate(user.startDate);
  const startYear = Number(formattedStartDate?.split('/')[2]);
  const startMonth = Number(formattedStartDate?.split('/')[1]);
  const yearsArraySinceStart = [];
  for (let i = startYear; i <= currentYear; i++) {
    yearsArraySinceStart.push(i);
  }

  const [dateModalShow, setDateModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number | ''>(Number(currentMonth));
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const [currentMonthBeingShow, setCurrentMonthBeingShow] = useState<MonthsNumbersType | ''>(
    Number(currentMonth) as MonthsNumbersType
  );
  const [currentYearBeingShow, setCurrentYearBeingShow] = useState(Number(currentYear));

  const {
    isLoadLoading,
    financialData,
    financialDataError,
    loadFinancial,
  } = useLoadFinancial();

  const {
    paymentVoucherUploadModalShow,
    paymentVoucherFileName,
    isSubmiting,
    handlePaymentVoucherFileUpload,
    handlePaymentVoucherFileSubmit,
    setPaymentVoucherUploadModalShow,
  } = useVoucherUpload({
    currentMonthBeingShow,
    currentYearBeingShow,
    loadFinancial,
  });

  const paymentInfoAtSelectedDate = financialData?.comps?.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const paymentDateInJsFormat = (paymentInfoAtSelectedDate
    ? new Date(paymentInfoAtSelectedDate?.data_envio) : null);
  const paymentDate = paymentDateInJsFormat?.toLocaleDateString('pt-BR');

  const nfInfoAtSelectedDate = financialData?.nfs?.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const nfDateInJsFormat = (nfInfoAtSelectedDate
    ? new Date(nfInfoAtSelectedDate?.data_envio) : null);
  const nfDate = nfDateInJsFormat?.toLocaleDateString('pt-BR');

  const dueDate = `08/${String(currentMonthBeingShow).length === 2 ? currentMonthBeingShow : `0${currentMonthBeingShow}`}/${currentYearBeingShow}`;

  const isLoading = isLoadLoading || isSubmiting;

  function handleTryAgain() {
    loadFinancial();
  }

  useEffect(() => {
    loadFinancial();
  }, [loadFinancial]);

  return {
    isLoading,
    months,
    currentYear,
    currentMonth,
    yearsArraySinceStart,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    paymentVoucherUploadModalShow,
    setPaymentVoucherUploadModalShow,
    handlePaymentVoucherFileSubmit,
    handlePaymentVoucherFileUpload,
    paymentVoucherFileName,
    startYear,
    startMonth,
    nfInfoAtSelectedDate,
    paymentInfoAtSelectedDate,
    dueDate,
    paymentDate,
    nfDate,
    financialDataError,
    handleTryAgain,
  };
}
