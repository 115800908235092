/* eslint-disable react/jsx-no-useless-fragment */
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import activate from '../../../../assets/images/icons/activate.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import { useAppContext } from '../../../../contexts/auth';
import { UserBeingActivateInterface } from '../../interfaces/UserBeingActivateInterface';
import { UserBeingDeletedInterface } from '../../interfaces/UserBeingDeletedInterface';
import { UserBeingSuspendedInterface } from '../../interfaces/UserBeingSuspendedInterface';
import { UsersList } from '../../interfaces/UsersList';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList:  UsersList[] | [];
  setUserBeingSuspended: Dispatch<SetStateAction<UserBeingSuspendedInterface>>;
  setModalSuspendShow: Dispatch<SetStateAction<boolean>>;
  setUserBeingActivate: Dispatch<SetStateAction<UserBeingActivateInterface>>;
  setModalActivateShow: Dispatch<SetStateAction<boolean>>;
  setUserBeingDeleted: Dispatch<SetStateAction<UserBeingDeletedInterface>>;
  setModalDeleteShow: Dispatch<SetStateAction<boolean>>;
}

export default function List({
  filteredList,
  setUserBeingSuspended,
  setModalSuspendShow,
  setUserBeingActivate,
  setModalActivateShow,
  setUserBeingDeleted,
  setModalDeleteShow,
}: ListInterface) {
  const idsLength = filteredList?.map((list) => list.id?.length);
  const bigIdsLength = idsLength?.filter((idLength) => idLength > 18);
  const hasAnyBigIdLength = bigIdsLength?.length > 0;

  const { user } = useAppContext();
  const { readOnly, viewByAssociatedCompanies } = user;

  return (
    <>
      {filteredList?.length > 0 && (
        <Container>
          <Row xs={1} md={2} lg={hasAnyBigIdLength ? 2 : 3}>
            {filteredList?.map((collaborator) => (
              <Col key={collaborator._id}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
                >
                  <Card>
                    <div className="info">
                      <div className="card-title">
                        <strong>{collaborator.id}</strong>
                        {(collaborator.status === 'Suspenso') && (
                          <small className="orange">Suspenso</small>
                        )}
                        {(collaborator.status === 'Ativo') && (
                          <small className="green">Ativo</small>
                        )}
                        {(collaborator.status === 'Registrado') && (
                          <small>Registrado</small>
                        )}
                      </div>
                      <span>
                        {collaborator.dataAtivacao !== 'Nunca ativado' && (
                          <>
                        Ativado em
                            {' '}
                            {collaborator.dataAtivacao}
                          </>
                        )}
                        {collaborator.dataAtivacao === 'Nunca ativado' && (
                          <>Não foi ativado</>
                        )}
                      </span>
                      {readOnly && (
                        <>
                          <span>
                            E-mail: {collaborator.email}
                          </span>
                          <span>
                            Celular: {collaborator.cellphone}
                          </span>
                        </>
                      )}

                      {viewByAssociatedCompanies && (
                        <span>
                        Empresa associada: {collaborator.associatedCompany ?? 'Não identificada'}
                        </span>
                      )}
                    </div>

                    {!readOnly && (
                      <div className="actions">
                        {(collaborator.status !== 'Suspenso') && (
                          <button
                            type="button"
                            onClick={() => {
                              setUserBeingSuspended(collaborator);
                              setModalSuspendShow(true);
                            }}
                          >
                            <img src={suspend} alt="suspend" title="Suspender benefício" />
                          </button>
                        )}

                        {collaborator.status === 'Suspenso' && (
                          <button
                            type="button"
                            onClick={() => {
                              setUserBeingActivate(collaborator);
                              setModalActivateShow(true);
                            }}
                          >
                            <img src={activate} alt="activate" title="Ativar benefício" />
                          </button>
                        )}

                        <button
                          type="button"
                          onClick={() => {
                            setUserBeingDeleted(collaborator);
                            setModalDeleteShow(true);
                          }}
                        >
                          <img src={trash} alt="delete" title="Excluir colaborador" />
                        </button>
                      </div>
                    )}

                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

      )}
    </>
  );
}
