import { useCallback, useState } from 'react';
import useApiCall from '../../../../hooks/useApiCall';
import dashboardService from '../../../../services/dashboardService';

type CollaboratorsInfoType = {
  active?: number | string,
  UF?: { [key: string]: number },
  average?: number | string,
}

export default function useCollaboratorsInfo() {
  const [collaboratorsInfo, setCollaboratorsInfo] = useState<CollaboratorsInfoType>({});
  const [hasCollaboratorsInfoError, setHasCollaboratorsInfoError] = useState(false);

  const { apiCall } = useApiCall();

  const getCollaboratorsInfo = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: dashboardService.getCollaboratorsInfo,
      queryParams: { associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setCollaboratorsInfo(apiResponse);
        setHasCollaboratorsInfoError(false);
      },
      catchMessage: 'Não foi possível carregar a informação dos colaboradores',
      catchAction: () => setHasCollaboratorsInfoError(true)
    });
  }, [apiCall]);

  return {
    collaboratorsInfo,
    hasCollaboratorsInfoError,
    getCollaboratorsInfo,
  };
}
