import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../components/FilterRadioButtonsContainer';
import MyModal from '../../../components/Modal';

interface DownloadModalInterface {
  downloadModalShow: boolean;
  setSelectedFilterDownload: Dispatch<SetStateAction<string>>;
  selectedFilterDownload: string;
  setDownloadModalShow: Dispatch<SetStateAction<boolean>>;
  downloadUsers: () => void;
}

export default function DownloadModal({
  downloadModalShow,
  setSelectedFilterDownload,
  selectedFilterDownload,
  setDownloadModalShow,
  downloadUsers,
}: DownloadModalInterface) {
  return (
    <MyModal
      show={downloadModalShow}
      title="Baixar planilha de beneficiários"
      centeredBody
      modalBody={(
        <>
          <h6>Você deseja baixar a planilha dos beneficiários com qual status?</h6>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => setSelectedFilterDownload('todos')}
              selected={selectedFilterDownload === 'todos'}
            >
              Todos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setSelectedFilterDownload('ativos')}
              selected={selectedFilterDownload === 'ativos'}
            >
              Ativos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setSelectedFilterDownload('suspensos')}
              selected={selectedFilterDownload === 'suspensos'}
            >
              Suspensos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setSelectedFilterDownload('registrados')}
              selected={selectedFilterDownload === 'registrados'}
            >
              Registrados
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
          {selectedFilterDownload && (
            <footer>
              <strong>
              ATENÇÃO:
              Será baixada a planilha com todos os beneficiários
              conforme filtro selecionado acima
              </strong>
            </footer>
          )}
        </>
      )}
      type="action"
      closeButtonLabel="Fechar"
      actionButtonLabel="Baixar"
      onClose={() => setDownloadModalShow(false)}
      onAction={() => downloadUsers()}
      isActionButtonDisabled={!selectedFilterDownload}
    />
  );
}
