import { ChangeEvent, useRef } from 'react';
import { FileInputContainer, StepsContainer, ChosenFileContainer } from './styles';
import Button from '../Button';
import Input from '../Input';
import arrow from '../../assets/images/icons/arrow.svg';

interface FileInputInterface {
  onFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  buttonLabel: string;
  isSubmitButtonDisabled: boolean;
  fileNameChoosed: string;
  onSubmitFile: () => void;
  acceptedFiles: string;
}

export default function FileInput({
  onFileUpload,
  buttonLabel,
  isSubmitButtonDisabled,
  fileNameChoosed,
  onSubmitFile,
  acceptedFiles,
}: FileInputInterface) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  return (
    <>
      <StepsContainer>
        <FileInputContainer>
          <Button small type="button" className="file" onClick={handleClick}>
            Escolher arquivo
          </Button>
          <Input
            type="file"
            style={{ display: 'none' }}
            ref={hiddenFileInput}
            onChange={onFileUpload}
            accept={acceptedFiles}
            autoComplete="new-password"
          />
        </FileInputContainer>

        <img src={arrow} className="rightArrow" alt="rightArrow" />

        <Button small type="button" disabled={isSubmitButtonDisabled} onClick={onSubmitFile}>
          {buttonLabel}
        </Button>

      </StepsContainer>
      <ChosenFileContainer>
        <small className="fileSmall">{`Arquivo escolhido: ${fileNameChoosed}`}</small>
      </ChosenFileContainer>
    </>
  );
}
