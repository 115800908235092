import NoData from '../../../../components/NoData';
import { Card } from '../../styles';

interface ActivesCardInterface {
  actives?: number | string;
}

export default function ActivesCard({ actives }: ActivesCardInterface) {
  return (
    <Card>
      <div className="card-title">
        <div>Adesão</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!actives && (
        <div className="card-main">
          <div>
            {actives}
          %
          </div>
          <small>
          Dos beneficiários se cadastraram.
          </small>
        </div>
      )}

      {(!actives) && (
        <NoData
          icon="emptyBox"
          label={(
            <>
            Não há dados disponíveis a respeito da adesão
            </>
          )}
        />
      )}
    </Card>
  );
}
