import {
  Routes, Route, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// pages
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import Financial from '../pages/Financial';
import ChangePassword from '../pages/ChangePassword';
import FAQ from '../pages/FAQ';
import NotFound from '../pages/NotFound';
import InternalUsers from '../pages/InternalUsers';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />

        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/collaborators" element={<Users />} />

        <Route path="/financial" element={<Financial />} />

        <Route path="/systemUsers" element={<InternalUsers />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="/faq" element={<FAQ />} />

        <Route path="/" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
