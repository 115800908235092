import PropTypes from 'prop-types';
import {
  createContext,
  ReactNode,
  useCallback, useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthService from '../services/authService';

import useApiCall from '../hooks/useApiCall';
import useLocalState from '../hooks/useLocalState';
import { AppContext } from '../interfaces/AppContext';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext({});

export function useAppContext(): AppContext | any {
  const context = useContext(AuthContext);

  return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [signed, setSigned] = useState(false);
  const [signedLocalStorage, setSignedLocalStorage] = useLocalState('validado');

  const [tokenJwt, setTokenJwt] = useState('');
  const [tokenLocalStorage, setTokenLocalStorage] = useLocalState('token');

  const [userData, setUserData] = useState({});
  const [userDataLocalStorage, setUserDataLocalStorage] = useLocalState('userData');

  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const login = useCallback(
    async (email: string, password: string) => {
      await apiCall({
        apiToCall: AuthService.auth,
        isLoginApi: true,
        reqBody: JSON.stringify({
          email,
          password,
        }),
        actionAfterResponse: (apiResponse) => {
          const { user, token } = apiResponse;
          if (user && token) {
            setSigned(true);
            setSignedLocalStorage(true);
            setTokenJwt(token);
            setTokenLocalStorage(token);
            setUserData({
              companyId: user._id,
              name: user.nome,
              email,
              collection: user.collection,
              startDate: user.inicio,
              monthlyFee: user.fee_mensal,
              readOnly: user.readOnly,
              associated_companies: user.associated_companies?.map((x: { name: string }) => x.name),
              viewByAssociatedCompanies: user.viewByAssociatedCompanies,
            });
            setUserDataLocalStorage({
              companyId: user._id,
              name: user.nome,
              email,
              collection: user.collection,
              startDate: user.inicio,
              monthlyFee: user.fee_mensal,
              readOnly: user.readOnly,
              associated_companies: user.associated_companies?.map((x: { name: string }) => x.name),
              viewByAssociatedCompanies: user.viewByAssociatedCompanies,
            });
            toast.success('Login realizado, bem vindo ao sistema administrativo Kiddlepass!');
            return;
          }
          setSigned(false);
          setSignedLocalStorage(false);
          const errorMessage = 'Login/senha inválidos!';
          toast.error(errorMessage);
        },
        catchMessage: 'Erro. Por favor, tente novamente',
        catchAction: () => {
          setSigned(false);
          setSignedLocalStorage(false);
          toast.error('Erro. Por favor, tente novamente');
        }
      });
    },
    [apiCall, setSignedLocalStorage, setTokenLocalStorage, setUserDataLocalStorage],
  );

  const signOut = useCallback(() => {
    toast.error('Sessão encerrada, realize o login novamente', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
    setSigned(false);
    setSignedLocalStorage(false);
    setTokenJwt('');
    setTokenLocalStorage('');

    setUserData({});
    setUserDataLocalStorage({});
    navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSignedLocalStorage, setTokenLocalStorage, setUserDataLocalStorage]);

  const appData = useMemo((): AppContext => ({
    signed: signedLocalStorage || signed,
    user: userDataLocalStorage || userData,
    token: tokenJwt || tokenLocalStorage,
    signOut,
    login,
  }), [signedLocalStorage, signed, userDataLocalStorage, userData, tokenJwt, tokenLocalStorage, signOut, login]);

  return (
    <>
      <AuthContext.Provider value={appData}>
        {children}
      </AuthContext.Provider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
