import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import useFinancial from './useFinancial';
import Header from './components/Header';

import DueDateCard from './components/DueDateCard';
import PaymentStatusCard from './components/PaymentStatusCard';
import NfDateCard from './components/NfDateCard';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import ChangeMonthModal from './components/ChangeMonthModal';
import PaymentVoucherUploadModal from './components/PaymentVoucherUploadModal';
import StartDateCard from './components/StartDateCard';
import AgreedAmounthCard from './components/AgreedAmounthCard';
import { useAppContext } from '../../contexts/auth';
import formatDate from '../../utils/formatDate';

type MonthsNumbersType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export default function Financial() {
  const monthsMap = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };
  const {
    isLoading,
    months,
    currentYear,
    currentMonth,
    yearsArraySinceStart,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    paymentVoucherUploadModalShow,
    setPaymentVoucherUploadModalShow,
    handlePaymentVoucherFileSubmit,
    handlePaymentVoucherFileUpload,
    paymentVoucherFileName,
    startYear,
    startMonth,
    nfInfoAtSelectedDate,
    paymentInfoAtSelectedDate,
    dueDate,
    paymentDate,
    nfDate,
    financialDataError,
    handleTryAgain,
  } = useFinancial();

  const { user } = useAppContext();
  const { startDate, monthlyFee } = user;

  return (
    <>
      <Sidebar active="Financial" />
      <Loader isLoading={isLoading} />
      <Transitions>
        {!financialDataError && !isLoading && (
          <>
            <Header
              currentMonthBeingShow={currentMonthBeingShow as MonthsNumbersType}
              currentYearBeingShow={currentYearBeingShow}
              handleDateModalShow={setDateModalShow}
              isNfUploaded={!!nfInfoAtSelectedDate}
              nfDownloadLink={nfInfoAtSelectedDate?.link}
              isPaymentVoucherUploaded={!!paymentInfoAtSelectedDate}
              paymentVoucherLink={paymentInfoAtSelectedDate?.link}
              handlePaymentVoucherModalShow={setPaymentVoucherUploadModalShow}
            />

            <Row xs={1} md={2} lg={2}>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.15, type: 'tween', stiffness: 10000 }}
                >
                  <StartDateCard
                    startDate={formatDate(startDate)}
                  />
                </motion.div>
              </Col>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >

                  <AgreedAmounthCard
                    agreedAmounth={monthlyFee}
                  />
                </motion.div>
              </Col>
            </Row>

            <Row xs={1} md={3} lg={3}>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.45, type: 'tween', stiffness: 10000 }}
                >

                  <DueDateCard
                    dueDate={dueDate}
                  />
                </motion.div>
              </Col>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.6, type: 'tween', stiffness: 10000 }}
                >

                  <PaymentStatusCard
                    paymentDate={paymentDate}
                  />
                </motion.div>
              </Col>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.75, type: 'tween', stiffness: 10000 }}
                >

                  <NfDateCard
                    nfDate={nfDate}
                  />
                </motion.div>
              </Col>
            </Row>

            <ChangeMonthModal
              dateModalShow={dateModalShow}
              yearsArraySinceStart={yearsArraySinceStart}
              setSelectedYear={setSelectedYear}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              months={months}
              selectedMonth={selectedMonth}
              currentYear={currentYear}
              currentMonth={currentMonth}
              startYear={startYear}
              startMonth={startMonth}
              monthsMap={monthsMap}
              setCurrentMonthBeingShow={setCurrentMonthBeingShow}
              setCurrentYearBeingShow={setCurrentYearBeingShow}
              setDateModalShow={setDateModalShow}
            />

            <PaymentVoucherUploadModal
              paymentVoucherUploadModalShow={paymentVoucherUploadModalShow}
              paymentVoucherFileName={paymentVoucherFileName}
              handlePaymentVoucherFileUpload={handlePaymentVoucherFileUpload}
              handlePaymentVoucherFileSubmit={handlePaymentVoucherFileSubmit}
              setPaymentVoucherUploadModalShow={setPaymentVoucherUploadModalShow}
            />
          </>
        )}

        {financialDataError && !isLoading && (
          <NoData
            icon="sad"
            label={(
              <>
              Ocorreu um erro ao obter os dados financeiros .
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              </>
            )}
          />
        )}

      </Transitions>
    </>
  );
}
