import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from '../../styles';

interface DueDateCardInterface {
  dueDate?: string;
}

export default function DueDateCard({ dueDate }: DueDateCardInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentDateNumber = Number(`${currentYear}${currentMonth}${currentDay}`);

  const dueDay = Number(dueDate?.split('/')[0]);
  const dueMonth = Number(dueDate?.split('/')[1]);
  const dueYear = Number(dueDate?.split('/')[2]);
  const dueDateNumber = Number(`${dueYear}${dueMonth}${dueDay}`);

  const isAfterCurrentDate = currentDateNumber > dueDateNumber;
  const isBeforeCurrentDate = dueDateNumber > currentDateNumber;
  const differenceBetweenDates = isAfterCurrentDate
    ? (intervalToDuration({
      start: new Date(currentYear, Number(currentMonth - 1), currentDay),
      end: new Date(dueYear, Number(dueMonth - 1), dueDay),
    }))
    : (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(dueYear, Number(dueMonth - 1), dueDay),
    }));

  const differenceBetweenDatesInDays = differenceBetweenDates.days;
  const differenceBetweenDatesInMonths = differenceBetweenDates?.months;
  const differenceBetweenDatesInYears = differenceBetweenDates?.years;

  return (
    <Card>
      <div className="card-title">
        <div>Data de vencimento</div>
      </div>

      {!!dueDate && (
        <div className="card-main">
          <div>
            {dueDate}
          </div>
          <small>
            {(isAfterCurrentDate && differenceBetweenDatesInDays != 0) && (
              `${differenceBetweenDatesInYears
                ? `${differenceBetweenDatesInYears} ${differenceBetweenDatesInYears != 1 ? 'anos' : 'ano'}, `
                : ''}
            ${differenceBetweenDatesInMonths
                ? `${differenceBetweenDatesInMonths} ${differenceBetweenDatesInMonths != 1 ? 'meses' : 'mês'}, e `
                : ''}
             ${differenceBetweenDatesInDays} dias atrás`
            )}
            {(isBeforeCurrentDate && differenceBetweenDatesInDays != 0) && (
              `Daqui a ${differenceBetweenDatesInDays} dia(s)`
            )}
            {differenceBetweenDatesInDays == 0 && (
              'Hoje'
            )}
          </small>
        </div>
      )}

      {(!dueDate) && (
        <div className="card-main">
          <div>
          Data de vencimento não disponível
          </div>
          <small>
          Tente verificar novamente mais tarde.
          </small>
        </div>
      )}
    </Card>
  );
}
