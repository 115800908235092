import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import financialService from '../../../../services/financialService';
import useApiCall from '../../../../hooks/useApiCall';

interface UseVoucherUploadInterface {
  currentMonthBeingShow: number | '';
  currentYearBeingShow: number;
  loadFinancial: () => void;
}

export default function useVoucherUpload({
  currentMonthBeingShow,
  currentYearBeingShow,
  loadFinancial,
}: UseVoucherUploadInterface) {
  const [paymentVoucherUploadModalShow, setPaymentVoucherUploadModalShow] = useState(false);
  const [paymentVoucherFileName, setPaymentVoucherFileName] = useState('');
  const [file, setFile] = useState<File[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { apiCall } = useApiCall();

  function handlePaymentVoucherFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setPaymentVoucherFileName(files[0].name);
      setFile([files[0]]);
    }
  }

  const handlePaymentVoucherFileSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.uploadNf,
      reqBody: [
        { key: 'doc', value: file[0] },
        { key: 'data', value: `${currentMonthBeingShow}_${currentYearBeingShow}` },
      ],
      onStartLoad: () => setIsSubmiting(true),
      onEndLoad: () => setIsSubmiting(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível realizar o upload do comprovante de pagamento. Por favor, tente novamente');
        }
        toast.success('Nota fiscal enviada com sucesso!');
        loadFinancial();
        setPaymentVoucherUploadModalShow(false);
      },
      catchMessage: 'Não foi possível realizar o upload do comprovante de pagamento. Por favor, tente novamente'
    });
  }, [apiCall, currentMonthBeingShow, currentYearBeingShow, file, loadFinancial]);

  return {
    paymentVoucherUploadModalShow,
    paymentVoucherFileName,
    isSubmiting,
    handlePaymentVoucherFileUpload,
    handlePaymentVoucherFileSubmit,
    setPaymentVoucherUploadModalShow,
  };
}
