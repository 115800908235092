import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface DashboardServiceInterface {
  companyName?: string,
  collection?: string,
  token: string;
  reqBody?: ReqBodyType,
  associatedCompany?: string | null
}

class DashboardService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getCollaboratorsInfo = async({
    token,
    associatedCompany,
  }: DashboardServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/dashboard/collaborators?associatedCompany=${encodeURIComponent(associatedCompany)}` : '/dashboard/collaborators',
      token,
    });
  };

  getKidsInfo = async({
    token,
    associatedCompany,
  }: DashboardServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/dashboard/kids?associatedCompany=${encodeURIComponent(associatedCompany)}` : '/dashboard/kids',
      token,
    });
  };

  getRatings = async({
    token,
    associatedCompany,
  }: DashboardServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/dashboard/ratings?associatedCompany=${encodeURIComponent(associatedCompany)}` : '/dashboard/ratings',
      token,
    });
  };

  getRanking = async({
    token,
    associatedCompany,
  }: DashboardServiceInterface) => {
    return this.httpClient.get({
      path: associatedCompany ? `/dashboard/ranking?associatedCompany=${encodeURIComponent(associatedCompany)}` : '/dashboard/ranking',
      token,
    });
  };
}

export default new DashboardService();
