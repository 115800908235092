import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import dashboardService from '../../../../services/dashboardService';
import useApiCall from '../../../../hooks/useApiCall';

export default function useRanking() {
  const [ranking, setRanking] = useState([]);
  const [hasRankingError, setHasRankingError] = useState(false);

  const { apiCall } = useApiCall();

  const getRanking = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: dashboardService.getRanking,
      queryParams: { associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          setHasRankingError(true);
          toast.error(`Não foi possível carregar a informação das avaliações (${apiResponse.erro})`, {
            toastId: 'getRankingFail',
          });
          return;
        }
        setRanking(apiResponse);
        setHasRankingError(false);
      },
      catchMessage: 'Não foi possível carregar a informação das avaliações',
      catchAction: () => setHasRankingError(true)
    });
  }, [apiCall]);

  return {
    ranking,
    hasRankingError,
    getRanking,
  };
}
