import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import AddInternalUserModal from './components/AddInternalUserModal';
import DeleteModal from './components/DeleteModal';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useInternalUsers from './useInternalUsers';

export default function InternalUsers() {
  const {
    isLoading,
    filteredInternalUsers,
    doesListApiHasError,
    loadInternalUsers,
    setIsLoading,
    internalUsers,
    searchTerm,
    handleChangeSearchTerm,
    handleOpenDeleteModal,
    internalUserBeingDeleted,
    setDeleteModalShow,
    deleteInternalUser,
    deleteModalShow,
    addInternalUserModalShow,
    setAddInternalUserModalShow,
  } = useInternalUsers();

  const hasInternalUsers = internalUsers?.length !== 0 && !!internalUsers && !isLoading;
  const filteredListLength = filteredInternalUsers?.length ?? 0;
  const anyResultsByFilterSelection = (!filteredInternalUsers || filteredInternalUsers.length === 0) && !isLoading && !doesListApiHasError && hasInternalUsers;
  const isListEmpty = !doesListApiHasError && (!isLoading && internalUsers.length === 0);

  return (
    <>
      <Sidebar active="SystemUsers" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasInternalUsers && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasInternalUsers}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="usuário"
          pluralLabel="usuários"
          setAddInternalUserModalShow={setAddInternalUserModalShow}
        />

        {hasInternalUsers && (
          <List
            filteredList={filteredInternalUsers}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme o termo de pesquisa.
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista dos cupons.
                <button type="button" onClick={() => loadInternalUsers()}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum usuário adicional de acesso cadastrado, clique no botão
                {' '}
                <strong>Novo usuário</strong>
                {' '}
                acima para cadastrar seu primeiro!
              </>
            )}
          />
        )}

        <AddInternalUserModal
          addInternalUserModalShow={addInternalUserModalShow}
          setAddInternalUserModalShow={setAddInternalUserModalShow}
          loadInternalUsers={loadInternalUsers}
          setIsLoading={setIsLoading}
        />

        <DeleteModal
          internalUserBeingDeleted={internalUserBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deleteInternalUser={deleteInternalUser}
          deleteModalShow={deleteModalShow}
        />
      </Transitions>
    </>
  );
}
