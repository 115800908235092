import { SecondaryButton } from './styles';
import { FilterRadioButtonInterface } from './interface';

export default function FilterRadioButton({
  children, onClick, selected, invisible, sameWidth,
}: FilterRadioButtonInterface) {
  return (
    <SecondaryButton
      onClick={onClick}
      selected={selected}
      invisible={invisible}
      sameWidth={sameWidth}
    >
      {children}
    </SecondaryButton>
  );
}
