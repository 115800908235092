import { useCallback, useState } from 'react';
import dashboardService from '../../../../services/dashboardService';
import useApiCall from '../../../../hooks/useApiCall';

type KidsInfoType = {
  average?: number,
  age?: {
    '0-2': number,
    '3-5': number,
    '6-8': number,
    '9-12': number,
  }
}

export default function useKidsInfo() {
  const [kidsInfo, setKidsInfo] = useState<KidsInfoType>({});
  const [hasKidsInfoError, setHasKidsInfoError] = useState(false);

  const { apiCall } = useApiCall();

  const getKidsInfo = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: dashboardService.getKidsInfo,
      queryParams: { associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setKidsInfo(apiResponse);
        setHasKidsInfoError(false);
      },
      catchMessage: 'Não foi possível carregar a informação das crianças',
      catchAction: () => setHasKidsInfoError(true)
    });
  }, [apiCall]);

  return {
    kidsInfo,
    hasKidsInfoError,
    getKidsInfo,
  };
}
