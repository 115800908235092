import MyModal from '../../../components/Modal';
import { UserBeingDeletedInterface } from '../interfaces/UserBeingDeletedInterface';
import { Dispatch, SetStateAction } from 'react';

interface DeleteModalInterface {
  modalDeleteShow: boolean;
  userBeingDeleted?: UserBeingDeletedInterface;
  setModalDeleteShow: Dispatch<SetStateAction<boolean>>;
  deleteCollaborator: () => void;
}

export default function DeleteModal({
  modalDeleteShow,
  userBeingDeleted,
  setModalDeleteShow,
  deleteCollaborator,
}: DeleteModalInterface) {
  return (
    <MyModal
      show={modalDeleteShow}
      title={`Excluir colaborador ${userBeingDeleted?.id}`}
      modalBody={(
        <>
          Tem certeza que deseja excluir o  colaborador
          {' '}
          <strong>
            {userBeingDeleted?.id}
          </strong>
          ?
          <footer>
            <strong>
              CUIDADO, ESTA AÇÃO É IRREVERSÍVEL
            </strong>
          </footer>
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Excluir"
      onClose={() => setModalDeleteShow(false)}
      onAction={() => deleteCollaborator()}
      type="deleteAction"
    />
  );
}
