import { ChangeEvent, useState } from 'react';
import useErrors from '../../../../hooks/useErrors';

export default function useHandleInputChanges() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  function handleCurrentPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setCurrentPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'currentPassword', message: 'Senha atual é obrigatória!' });
    } else {
      removeError('currentPassword');
    }
  }

  function handleNewPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setNewPassword(event.target.value);

    if (event.target.value.length < 6) {
      setError({ field: 'newPassword', message: 'Senha seve conter no mínimo 6 caracteres!' });
    } else if (event.target.value.length >= 6) {
      removeError('newPassword');
    }

    if (event.target.value !== newPasswordConfirmation) {
      setError({ field: 'newPasswordConfirmation', message: 'Senhas divergentes' });
    } else if (event.target.value === newPasswordConfirmation) {
      removeError('newPasswordConfirmation');
    }
  }

  function handleNewPasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setNewPasswordConfirmation(event.target.value);

    if (event.target.value !== newPassword) {
      setError({ field: 'newPasswordConfirmation', message: 'Senhas divergentes' });
    } else {
      removeError('newPasswordConfirmation');
    }
  }

  return {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
    handleCurrentPasswordChange,
    handleNewPasswordChange,
    handleNewPasswordConfirmationChange,
    getErrorMessageByFieldName,
    errors,
  };
}
