import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import FileInput from '../../../components/FileInput';
import FilterRadioButton from '../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../components/FilterRadioButtonsContainer';
import MyModal from '../../../components/Modal';

interface ImportCsvModalInterface {
  modalImportCsvShow: boolean;
  setCsvAction: Dispatch<SetStateAction<string>>;
  csvAction: string;
  csvFileName: string;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmitFile: () => void;
  handleImportModalClose: () => void;
}

export default function ImportCsvModal({
  modalImportCsvShow,
  setCsvAction,
  csvAction,
  csvFileName,
  handleFileUpload,
  handleSubmitFile,
  handleImportModalClose,
}: ImportCsvModalInterface) {
  return (
    <MyModal
      show={modalImportCsvShow}
      title="Importar usuários por CSV"
      centeredBody
      modalBody={(
        <>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => setCsvAction('add')}
              selected={csvAction === 'add'}
            >
              Adicionar
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setCsvAction('delete')}
              selected={csvAction === 'delete'}
            >
              Remover
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
          <FileInput
            buttonLabel="Importar arquivo"
            fileNameChoosed={csvFileName}
            isSubmitButtonDisabled={!csvFileName || !csvAction}
            onFileUpload={handleFileUpload}
            onSubmitFile={handleSubmitFile}
            acceptedFiles=".csv"
          />
          {csvAction === 'add' && (
            <footer>
              <strong>
              ATENÇÃO:
              Esta ação irá incluir todos os colaboradores contidos na planilha!
              </strong>
            </footer>
          )}
          {csvAction === 'delete' && (
            <footer>
              <strong>
              ATENÇÃO:
              Esta ação irá remover todos os colaboradores contidos na planilha!
                <br />
              CUIDADO, ESTA AÇÃO É IRREVERSÍVEL
              </strong>
            </footer>
          )}
        </>
      )}
      closeButtonLabel="Fechar"
      onClose={handleImportModalClose}
      type="info"
    />
  );
}
