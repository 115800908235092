import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import { useAppContext } from '../../../../contexts/auth';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  filePathDownload: string;
  showDownloadModal: () => void;
  isManualCreateAtModal?: boolean;
  handleImportShow: () => void;
  handleCreateModalShow: () => void;
  onPageChange: (page: number) => void;
  pagesQuantity?: number;
  currentPage: number;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  filePathDownload,
  showDownloadModal,
  isManualCreateAtModal = false,
  handleImportShow,
  handleCreateModalShow,
  onPageChange,
  pagesQuantity = 0,
  currentPage,
}: ListHeaderInterface) {
  const { user } = useAppContext();
  const { readOnly } = user;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength || 0}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
        {!readOnly && (
          <div className="actionButtons">
            <Link to={filePathDownload} target="_blank" download>
            Exemplo CSV
            </Link>
            <button type="button" onClick={handleImportShow}>Importar CSV</button>
            {filteredListLength > 0 && (
              <button type="button" onClick={showDownloadModal}>Download</button>
            )}
            {isManualCreateAtModal && (
              <button type="button" onClick={handleCreateModalShow}>Novo beneficiário</button>
            )}
          </div>
        )}
      </Header>
    </motion.div>
  );
}
