/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UserBeingSuspendedInterface } from '../../interfaces/UserBeingSuspendedInterface';
import useApiCall from '../../../../hooks/useApiCall';

interface UseSuspendCollaboratorInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
}

export default function useSuspendCollaborator({
  loadUsers,
  currentPage,
}: UseSuspendCollaboratorInterface) {
  const [isSuspending, setIsSuspending] = useState(false);
  const [modalSuspendShow, setModalSuspendShow] = useState(false);
  const [userBeingSuspended, setUserBeingSuspended] = useState<UserBeingSuspendedInterface>({});

  const { apiCall } = useApiCall();

  const suspendCollaborator = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.updateUserStatus,
      reqBody: JSON.stringify({
        id: userBeingSuspended.id,
        status: 'suspender',
      }),
      onStartLoad: () => {
        setModalSuspendShow(false);
        setIsSuspending(true);
      },
      onEndLoad: () => setIsSuspending(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível suspender o benefício do colaborador. Por favor, tente novamente');
          setIsSuspending(false);
          return;
        }
        toast.success('Beneficio do colaborador suspenso com sucesso!');
        loadUsers(currentPage);
      },
      catchMessage: 'Não foi possível suspender o benefício do colaborador. Por favor, tente novamente',
    });
  }, [apiCall, userBeingSuspended.id, loadUsers, currentPage]);

  return {
    isSuspending,
    modalSuspendShow,
    setModalSuspendShow,
    userBeingSuspended,
    setUserBeingSuspended,
    suspendCollaborator,
  };
}
