import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import authService from '../../../../services/authService';
import useApiCall from '../../../../hooks/useApiCall';

interface UseHandleSubmitInterface {
  currentPassword: string;
  newPassword: string;
}

export default function useHandleSubmit({
  currentPassword,
  newPassword,
}: UseHandleSubmitInterface) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAppContext();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const handleSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: authService.changePassword,
      reqBody: JSON.stringify({
        email: user.email,
        currentPassword,
        newPassword,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar a senha. Por favor, tente novamente');
          setIsLoading(false);
          return;
        }
        toast.success('Senha alterada com sucesso!');
        navigate('/');
      },
      catchMessage: 'Não foi possível alterar a senha. Por favor, tente novamente'
    });
  }, [apiCall, currentPassword, navigate, newPassword, user.email]);

  return { handleSubmit, isLoading };
}
