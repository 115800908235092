/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import { ModalContainer } from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { Group } from '../../styles';
import FormGroup from '../../../../components/FormGroup';
import useAddModal from './useAddModal';

interface IInternalUsersModal {
  addInternalUserModalShow: boolean;
  setAddInternalUserModalShow: Dispatch<SetStateAction<boolean>>;
  loadInternalUsers: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddInternalUserModal({
  addInternalUserModalShow,
  setAddInternalUserModalShow,
  loadInternalUsers,
  setIsLoading,
}: IInternalUsersModal) {
  const {
    addInternalUser,
    email,
    password,
    passwordConfirmation,
    handleEmailChange,
    handlePasswordChange,
    handlePasswordConfirmationChange,
    getErrorMessageByFieldName,
    isInternalUserFormValid,
  } = useAddModal({ loadInternalUsers, setAddInternalUserModalShow, setIsLoading });

  return (
    <MyModal
      show={addInternalUserModalShow}
      onClose={() => setAddInternalUserModalShow(false)}
      title={'Adicionar novo usuário'}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
                E-mail
              </div>
              <FormGroup error={getErrorMessageByFieldName('email')}>
                <Input
                  value={email}
                  onChange={handleEmailChange}
                  error={getErrorMessageByFieldName('email')}
                  placeholder="E-mail de login"
                />
              </FormGroup>
            </Group>

            <Group>
              <div className="title">
                Senha
              </div>
              <FormGroup error={getErrorMessageByFieldName('password')}>
                <Input
                  value={password}
                  onChange={handlePasswordChange}
                  error={getErrorMessageByFieldName('password')}
                  type="password"
                  placeholder="Insira uma senha inicial"
                />
              </FormGroup>
            </Group>

            <Group>
              <div className="title">
                Confirmação de senha
              </div>
              <FormGroup error={getErrorMessageByFieldName('passwordConfirmation')}>
                <Input
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  error={getErrorMessageByFieldName('passwordConfirmation')}
                  type="password"
                  placeholder="Confirme a senha"
                />
              </FormGroup>
            </Group>

          </OpacityAnimation>
        </ModalContainer>
      )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Adicionar"
      isActionButtonDisabled={!isInternalUserFormValid}
      onAction={() => addInternalUser()}
    />
  );
}
