/* eslint-disable react/jsx-no-useless-fragment */
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';

import Loader from '../../components/Loader';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';

import NoData from '../../components/NoData';
import { useAppContext } from '../../contexts/auth';
import ActivesCard from './components/ActivesCard';
import CompanySelection from './components/CompanySelection';
import KidsAgeCard from './components/KidsAgeCard';
import KidsAverageCard from './components/KidsAverageCard';
import RankingCard from './components/RankingCard';
import RatingsCard from './components/RatingsCard';
import useDashboard from './useDashboard';

export default function Dashboard() {
  const {
    isLoading,
    collaboratorsInfo,
    kidsInfo,
    ranking,
    ratings,
    hasCollaboratorsInfoError,
    hasKidsInfoError,
    hasRatingsError,
    hasRankingError,
    handleTryAgain,
    handleCompanyChange,
    selectedCompany,
  } = useDashboard();

  const { user } = useAppContext();
  const { readOnly, associated_companies, viewByAssociatedCompanies } = user;

  const hasSomeError = (
    hasCollaboratorsInfoError
    || hasKidsInfoError
    || hasRatingsError
    || hasRankingError);

  return (
    <>
      <Sidebar active="Dashboard" />
      <Transitions>
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <>
            {viewByAssociatedCompanies && (
              <CompanySelection
                companyOptions={associated_companies}
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
              />
            )}
            {!hasSomeError && (
              <Container>
                <Row xs={1} md={2} lg={readOnly ? 2 : 3}>
                  {!readOnly && (
                    <Col>
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.15, type: 'tween', }}
                      >
                        <ActivesCard
                          actives={collaboratorsInfo.active}
                        />
                      </motion.div>
                    </Col>
                  )}

                  <Col>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.3, type: 'tween', }}
                    >
                      <KidsAverageCard
                        average={Number(kidsInfo.average)}
                      />
                    </motion.div>
                  </Col>

                  <Col>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.45, type: 'tween', }}
                    >
                      <RatingsCard
                        rating={ratings.ratings}
                      />
                    </motion.div>
                  </Col>
                </Row>

                <Row xs={1} md={2} lg={2}>
                  <Col>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.75, type: 'tween', }}
                    >
                      <KidsAgeCard
                        ages={kidsInfo.age}
                      />
                    </motion.div>
                  </Col>

                  <Col>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.9, type: 'tween', }}
                    >
                      <RankingCard
                        ranking={ranking}
                      />
                    </motion.div>
                  </Col>
                </Row>
              </Container>
            )}

            {hasSomeError && (
              <NoData
                icon="sad"
                label={(
                  <>
                    Ocorreu um erro ao obter os dados do dashboard.
                    <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                    .
                  </>
                )}
              />
            )}
          </>
        )}
      </Transitions>
    </>
  );
}
