/* eslint-disable max-len */
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import { Container, Info } from './styles';
import FilterRadioButton from '../../components/FilterRadioButtons';
import kiddleLogo from '../../assets/images/icons/kiddleLogo.svg';

export default function FAQ() {
  const [selectedMaterial, setSelectedMaterial] = useState('comoFunciona');
  return (
    <>
      <Sidebar active="FAQ" />
      <Transitions>
        <Container>
          <h1>Documentos auxiliares</h1>

          <div className="selectionButtons">
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('comoFunciona')}
              selected={selectedMaterial === 'comoFunciona'}
            >
              Como funciona?
            </FilterRadioButton>
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('perguntasFrequentes')}
              selected={selectedMaterial === 'perguntasFrequentes'}
            >
              Perguntas frequentes
            </FilterRadioButton>
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('logos')}
              selected={selectedMaterial === 'logos'}
            >
              Nossos logotipos
            </FilterRadioButton>
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('kiddleData')}
              selected={selectedMaterial === 'kiddleData'}
            >
              Dados Gerais e de cobrança
            </FilterRadioButton>
          </div>

          {selectedMaterial === 'comoFunciona' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 500 }} />
                <div>
                  <span>
                  Baixe este documento para ter acesso ao material “Perguntas Frequentes”. Nele você encontrará informações quanto ao funcionamento do produto, dúvidas quanto ao que somos, quais os tipos de atividades existentes em nossa plataforma, quantidade de crianças que podem utilizar o mesmo plano, como é realizada a liberação do aplicativo para elegíveis, e como funciona o suporte às famílias são respondidas aqui!
                  </span>

                  <Link to="/files/faq/Como funciona.pdf" target="_blank" download>
                  Baixar Material (.pdf)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

          {selectedMaterial === 'perguntasFrequentes' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 300 }} />
                <div>
                  <span>
                  Baixe este documento para ter acesso ao material “Perguntas Frequentes Uso Externo” que pode ser compartilhado com seus colaboradores. Nele seu time encontrará informações quanto ao funcionamento do produto e ativação do benefício.
                  </span>

                  <Link to="/files/faq/Perguntas frequentes.pdf" target="_blank" download>
                  Baixar Material (.pdf)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

          {selectedMaterial === 'logos' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 300 }} />
                <div>
                  <span>
                  Baixe este documento para ter acesso as nossas variações de logomarcas e guia de uso. Para acessar basta descompactar o arquivo após baixá-lo. Usando o Windows, para descompactar o arquivo, basta clicar no mesmo com o botão direito, e selecionar a opção Extrair Para, ou Extrair Aqui!
                  </span>

                  <Link to="/files/faq/Logos.zip" target="_blank" download>
                  Baixar Material (.zip)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

          {selectedMaterial === 'kiddleData' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 200 }} />
                <div>
                  <span>
                    Baixe este documento para ter acesso aos nossos dados gerais e bancários que podem ser utilizados para realizar eventuais pagamentos, e checar dados como nosso CNPJ, razão social, e endereço!
                  </span>

                  <Link to="/files/faq/Dados Cobrança KP.pdf" target="_blank" download>
                    Baixar Material (.pdf)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

        </Container>
      </Transitions>
    </>
  );
}
