import Papa from 'papaparse';
import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import hasDesiredKeysAndValues from '../../../../utils/hasDesiredKeysAndValues';
import removeDuplicates from '../../../../utils/removeDuplicates';

interface UseUploadFileInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
}

interface CsvDataInterface {
  id: string;
  'Empresa Associada'?: string | null
}

interface CustomParseResult<T> extends Papa.ParseResult<T> {
  data: T[];
}

export default function useUploadFile({
  loadUsers,
  currentPage,
}: UseUploadFileInterface) {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [csvFileName, setCsvFileName] = useState('');
  const [csvParsed, setCsvParsed] = useState<CsvDataInterface[]>([]);
  const [modalImportCsvShow, setModalImportCsvShow] = useState(false);
  const [csvAction, setCsvAction] = useState('');

  const { apiCall } = useApiCall();
  const { user } = useAppContext();
  const { viewByAssociatedCompanies } = user;

  function parseFile(file: File):Promise<CustomParseResult<any>> {
    return new Promise((resolve, reject) => {
      const parsedData: any[] = [];

      Papa.parse(file, {
        header: true,
        chunkSize: 5000,
        chunk: (results, parser) => {
          const data: Array<any> = results.data;
          const csvParsedWithoutNullValues = data.filter((list) => !!list.id);
          const removedDuplicatedIds = removeDuplicates(csvParsedWithoutNullValues);

          const desiredHeaders = viewByAssociatedCompanies ? ['id', 'Empresa Associada'] : ['id'];
          const hasDesiredHeadersAndValues = hasDesiredKeysAndValues(removedDuplicatedIds, desiredHeaders);
          if (!hasDesiredHeadersAndValues) {
            reject(new Error('Identificamos que o arquivo enviado não está com os mesmos cabeçalhos do modelo a ser seguido, ou algum campo foi preenchdio em branco'));
            parser.abort();
            return;
          }

          const mappedArray = removedDuplicatedIds.map((index) => ({
            id: String(index.id).toLowerCase(),
            associated_company: String(index['Empresa Associada']),
          }));
          parsedData.push(...mappedArray);
        },
        complete: () => {
          const customResult: CustomParseResult<any> = {
            data: parsedData,
            errors: [],
            meta: {
              delimiter: '',
              linebreak: '',
              aborted: false,
              truncated: false,
              cursor: 0,
            },
          };
          resolve(customResult);
        },
        error: reject,
      });
    });
  }

  async function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      const results = await toast.promise(parseFile(files[0]), {
        pending: 'Aguarde, estamos carregando este arquivo',
        success: 'Seu arquivo está válido. Agora basta importá-lo!',
        error: 'Ocorreu um erro ao carregar seu arquivo',
      });
      const resultArray: CsvDataInterface[] = results.data;

      setCsvFileName(files[0].name);
      setCsvParsed(resultArray);
    }
  }

  const handleSubmitFile = useCallback(async () => {
    await apiCall({
      apiToCall: (
        csvAction === 'add'
          ? usersService.createMultiUsers
          : usersService.deleteUser
      ),
      reqBody: JSON.stringify({
        list: (
          csvAction === 'add'
            ? csvParsed
            : csvParsed.map((index) => index.id)
        ),
      }),
      onStartLoad: () => {
        setModalImportCsvShow(false);
        setIsSubmittingFile(true);
      },
      onEndLoad: () => setIsSubmittingFile(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsSubmittingFile(false);
          toast.error(csvAction === 'add'
            ? 'Não foi possível importar os colaboradores'
            : 'Não foi possível remover os colaboradores');
          return;
        }
        loadUsers(currentPage);
        setIsSubmittingFile(false);
        toast.success(csvAction === 'add'
          ? 'Colaboradores importados com sucesso'
          : 'Colaboradores removidos com sucesso');
      },
      catchMessage: (csvAction === 'add'
        ? 'Não foi possível importar os colaboradores'
        : 'Não foi possível remover os colaboradores'),
      catchAction: () => setIsSubmittingFile(false),
    });
  }, [csvParsed, csvAction, apiCall, loadUsers, currentPage]);

  function handleImportShow() {
    setModalImportCsvShow(true);
  }

  function handleImportModalClose() {
    setModalImportCsvShow(false);
  }

  return {
    isSubmittingFile,
    csvFileName,
    modalImportCsvShow,
    csvAction,
    setCsvAction,
    handleFileUpload,
    handleSubmitFile,
    handleImportShow,
    handleImportModalClose,
  };
}
