import { Link } from 'react-router-dom';

import { addHours, format } from 'date-fns';
import arrow from '../../assets/images/icons/arrow.svg';
import { Container } from './styles';

interface PageHeaderInterface {
  title: string;
  link?: string;
  lastUpdate?: Date | string;
}

export default function PageHeader({ title, link, lastUpdate }: PageHeaderInterface) {
  return (
    <Container>
      {link && (
        <Link to={link}>
          <img src={arrow} alt="back" className="primaryColor" />
          <span>Voltar</span>
        </Link>
      )}
      <h1>{title}</h1>
      {lastUpdate && <small>Última planilha importada em {format(addHours(new Date(lastUpdate), 3), 'dd/MM/yyyy \'às\' HH\'h\'mm\'m\'')}</small>}
    </Container>
  );
}
