import { Card } from '../../styles';
import NoData from '../../../../components/NoData';
import trophyFirst from '../../../../assets/images/icons/trophyFirst.svg';
import trophySecond from '../../../../assets/images/icons/trophySecond.svg';
import trophyThird from '../../../../assets/images/icons/trophyThird.svg';
import { RankingList } from './styles';

interface RankingCardInterface {
  ranking: string[];
}

export default function RankingCard({ ranking }: RankingCardInterface) {
  return (
    <Card>
      <div className="card-title">
        <div>Ranking aulas mais agendadas</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {ranking.length !== 0 && (
        <RankingList>
          {ranking[0] && (
            <div>
              <img src={trophyFirst} alt="" />
              <span>{ranking[0]}</span>
            </div>
          )}
          {ranking[1] && (
            <div>
              <img src={trophySecond} alt="" />
              <span>{ranking[1]}</span>
            </div>
          )}
          {ranking[2] && (
            <div>
              <img src={trophyThird} alt="" />
              <span>{ranking[2]}</span>
            </div>
          )}
          {ranking[3] && (
            <div>
              <strong>#4</strong>
              <span>{ranking[3]}</span>
            </div>
          )}
          {ranking[4] && (
            <div>
              <strong>#5</strong>
              <span>{ranking[4]}</span>
            </div>
          )}
        </RankingList>
      )}

      {(ranking.length === 0) && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Não há dados disponíveis a respeito do ranking de aulas mais agendadas
            </>
          )}
        />
      )}
    </Card>
  );
}
