import Spinner from '../Spinner';
import { Container } from './styles';
import { FormGroupInterface } from './interface';


export default function FormGroup({
  children, error, isLoading,
}: FormGroupInterface) {
  return (
    <Container>
      <div className="form-item">
        { children }

        {isLoading && (
          <div className="loader">
            <Spinner size={14} />
          </div>
        )}
      </div>
      { error && <small>{error}</small> }
    </Container>
  );
}
