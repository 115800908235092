import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface InternalUsersServiceInterface {
  id?: string;
  token: string;
  reqBody?: ReqBodyType;
}

class InternalUsersService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getInternalUsers = async ({
    token,
  }: InternalUsersServiceInterface) => {
    return this.httpClient.get({
      path: '/users-company',
      token,
    });
  };

  createInternalUser = async({
    reqBody,
    token,
  }: InternalUsersServiceInterface) => {
    return this.httpClient.post({
      path: '/users-company',
      reqBody,
      token,
    });
  };

  deleteInternalUser = ({
    id,
    token,
  }: InternalUsersServiceInterface) => {
    return this.httpClient.delete({
      path: `/users-company/${id}`,
      token,
    });
  };
}

export default new InternalUsersService();
