import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ModalStyles } from './styles';
import { ReactNode } from 'react';

interface MyModalInterface {
  title: string;
  closeButtonLabel?: string;
  actionButtonLabel?: string;
  isActionButtonDisabled?: boolean;
  modalBody: ReactNode;
  onClose: () => void;
  onAction?: () => void;
  show: boolean;
  type?: 'info' | 'action' | 'deleteAction' | 'suspendAction' | 'activateAction';
  size?: 'sm' | 'lg' | 'xl';
  grid?: boolean;
  centeredBody?: boolean;
}

export default function MyModal({
  title,
  closeButtonLabel = 'Fechar',
  actionButtonLabel = 'Confirmar',
  isActionButtonDisabled = false,
  modalBody,
  onClose,
  onAction,
  show,
  type = 'info',
  size,
  grid,
  centeredBody,
}: MyModalInterface) {
  return (
    <Modal
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <ModalStyles centeredBody={centeredBody}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={grid ? 'show-grid' : undefined}>
          {modalBody}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>{closeButtonLabel}</Button>
          {type === 'action' && (
            <Button onClick={onAction} disabled={isActionButtonDisabled}>
              {actionButtonLabel}
            </Button>
          )}
          {type === 'deleteAction' && (
            <Button variant="danger" onClick={onAction} disabled={isActionButtonDisabled}>
              {actionButtonLabel}
            </Button>
          )}
          {type === 'suspendAction' && (
            <Button variant="warning" onClick={onAction} disabled={isActionButtonDisabled}>
              {actionButtonLabel}
            </Button>
          )}
          {type === 'activateAction' && (
            <Button variant="success" onClick={onAction} disabled={isActionButtonDisabled}>
              {actionButtonLabel}
            </Button>
          )}
        </Modal.Footer>
      </ModalStyles>
    </Modal>
  );
}
