import { useCallback, useState } from 'react';
import dashboardService from '../../../../services/dashboardService';
import useApiCall from '../../../../hooks/useApiCall';

type RatingsType = {
  ratings?: number,
}

export default function useRatings() {
  const [ratings, setRatings] = useState<RatingsType>({});
  const [hasRatingsError, setHasRatingsError] = useState(false);

  const { apiCall } = useApiCall();

  const getRatings = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: dashboardService.getRatings,
      queryParams: { associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setRatings(apiResponse);
        setHasRatingsError(false);
      },
      catchMessage: 'Não foi possível carregar a informação das avaliações',
      catchAction: () => setHasRatingsError(true)
    });
  }, [apiCall]);

  return {
    ratings,
    hasRatingsError,
    getRatings,
  };
}
