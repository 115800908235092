import { useCallback, useState } from 'react';
import financialService from '../../../../services/financialService';
import useApiCall from '../../../../hooks/useApiCall';

type FinancialDataType = {
  comps?: {
    data: string,
    link: string,
    data_envio: string,
  }[],
  nfs?: {
    data: string,
    link: string,
    data_envio: string,
  }[]
}

export default function useLoadFinancial() {
  const [isLoading, setIsLoading] = useState(false);
  const [financialData, setFinancialData] = useState<FinancialDataType>({});
  const [financialDataError, setFinancialDataError] = useState(false);

  const { apiCall } = useApiCall();

  const loadFinancial = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.getFinancialData,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        setFinancialData(apiResponse.details);
        setFinancialDataError(false);
      },
      catchMessage: 'Não foi possível carregar os dados financeiros',
      catchAction: () => setFinancialDataError(true)
    });
  }, [apiCall]);

  return {
    isLoadLoading: isLoading,
    financialData,
    financialDataError,
    loadFinancial,
  };
}
