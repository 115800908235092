import MyModal from '../../../components/Modal';
import { UserBeingActivateInterface } from '../interfaces/UserBeingActivateInterface';
import { Dispatch, SetStateAction } from 'react';

interface ActivateModalInterface {
  modalActivateShow: boolean;
  userBeingActivate?: UserBeingActivateInterface;
  setModalActivateShow: Dispatch<SetStateAction<boolean>>;
  activateCollaborator: () => void;
}

export default function ActivateModal({
  modalActivateShow,
  userBeingActivate,
  setModalActivateShow,
  activateCollaborator,
}: ActivateModalInterface) {
  return (
    <MyModal
      show={modalActivateShow}
      title={`Reativar colaborador ${userBeingActivate?.id}`}
      modalBody={(
        <>
          Tem certeza que deseja reativar o benefício do colaborador
          {' '}
          <strong>
            {userBeingActivate?.id}
          </strong>
          ?
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Reativar"
      onClose={() => setModalActivateShow(false)}
      onAction={() => activateCollaborator()}
      type="activateAction"
    />
  );
}
