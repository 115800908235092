import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import useCollaboratorsInfo from './functions/useCollaboratorsInfo';
import useKidsInfo from './functions/useKidsInfo';
import useRanking from './functions/useRanking';
import useRatings from './functions/useRatings';

export default function useDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{ value: string | null, label: string }>({ value: null, label: 'Visão Geral' });

  const {
    collaboratorsInfo,
    hasCollaboratorsInfoError,
    getCollaboratorsInfo,
  } = useCollaboratorsInfo();

  const {
    kidsInfo,
    hasKidsInfoError,
    getKidsInfo,
  } = useKidsInfo();

  const {
    ratings,
    hasRatingsError,
    getRatings,
  } = useRatings();

  const {
    ranking,
    hasRankingError,
    getRanking,
  } = useRanking();

  const handleCompanyChange = useCallback((company: { value: string | null, label: string }) => {
    setSelectedCompany(company);
  }, []);

  const loadDashboard = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        getCollaboratorsInfo(selectedCompany.value),
        getKidsInfo(selectedCompany.value),
        getRatings(selectedCompany.value),
        getRanking(selectedCompany.value),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar o dashboard (${error})`, {
        toastId: 'loadDashboardFail',
      });
    } finally {
      setIsLoading(false);
    }
  }, [getCollaboratorsInfo, getKidsInfo, getRanking, getRatings, selectedCompany]);

  useEffect(() => {
    loadDashboard();
  }, [getCollaboratorsInfo, getKidsInfo, getRatings, getRanking, loadDashboard]);

  function handleTryAgain() {
    loadDashboard();
  }

  return {
    isLoading,
    collaboratorsInfo,
    kidsInfo,
    ranking,
    ratings,
    hasCollaboratorsInfoError,
    hasKidsInfoError,
    hasRatingsError,
    hasRankingError,
    handleTryAgain,
    handleCompanyChange,
    selectedCompany,
  };
}
