import Select from 'react-select';
import useThemeContext from '../../../contexts/theme';
import { CustomStyle } from '../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../components/CustomSelectStyleDarkTheme';

interface CompanySelectionI {
  companyOptions: string[];
  handleCompanyChange: (company: { value: string | null, label: string }) => void;
  selectedCompany: { value: string | null, label: string }
}

export default function CompanySelection({ companyOptions, handleCompanyChange, selectedCompany }: CompanySelectionI) {
  const { selectedTheme } = useThemeContext();

  const companyOptionsMapped = companyOptions.map((cp) => ({ value: cp, label: cp }));

  const options = [
    { value: null, label: 'Visão Geral '},
    ...companyOptionsMapped,
  ];

  return (
    <div style={{ width: '350px'}}>
      <Select
        value={selectedCompany}
        options={options}
        onChange={(company) => handleCompanyChange(company!)}
        placeholder="Visão Geral"
        styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
        classNamePrefix="react-select"
        className="react-select-container"
      />
    </div>
  );
}
