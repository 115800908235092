import MyModal from '../../../components/Modal';
import { UserBeingSuspendedInterface } from '../interfaces/UserBeingSuspendedInterface';
import { Dispatch, SetStateAction } from 'react';

interface SuspendModalInterface {
  modalSuspendShow: boolean;
  userBeingSuspended?: UserBeingSuspendedInterface;
  setModalSuspendShow: Dispatch<SetStateAction<boolean>>;
  suspendCollaborator: () => void;
}

export default function SuspendModal({
  modalSuspendShow,
  userBeingSuspended,
  setModalSuspendShow,
  suspendCollaborator,
}: SuspendModalInterface) {
  return (
    <MyModal
      show={modalSuspendShow}
      title={`Suspender colaborador ${userBeingSuspended?.id}`}
      modalBody={(
        <>
          Tem certeza que deseja suspender o benefício do colaborador
          {' '}
          <strong>
            {userBeingSuspended?.id}
          </strong>
          ?
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Suspender"
      onClose={() => setModalSuspendShow(false)}
      onAction={() => suspendCollaborator()}
      type="suspendAction"
    />
  );
}
