import PropTypes from 'prop-types';
import { Container, MenuItem } from './styles';
import exit from '../../assets/images/icons/exit.svg';

import { useAppContext } from '../../contexts/auth';
import AdminNavbar from './components/AdminNavbar';
import ReadOnlyNavbar from './components/ReadOnlyNavbar';
import { SidebarInterface } from './interface';

export default function Navbar({ active }: SidebarInterface) {
  const { signOut } = useAppContext();
  const appContext = useAppContext();
  const { readOnly } = appContext.user;

  return (
    <Container>
      {readOnly ? <ReadOnlyNavbar active={active} /> : <AdminNavbar active={active} />}

      <MenuItem onClick={() => signOut()}>
        <img src={exit} alt="exit" />
        Sair
      </MenuItem>

    </Container>
  );
}

Navbar.propTypes = {
  active: PropTypes.string.isRequired,
};
