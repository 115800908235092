import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface FinancialServiceInterface {
  companyId?: string,
  reqBody?: ReqBodyType,
  token: string,
}

class AuthService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getFinancialData = async({
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.get({
      path: '/financial',
      token,
    });
  };

  uploadNf = async({
    reqBody,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.post({
      path: '/financial',
      reqBody,
      contentType: 'multipart/form-data',
      token,
    });
  };
}

export default new AuthService();
