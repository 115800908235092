import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import ExportXlsx from '../../../../utils/ExportXlsx';

type CollaboratorType = {
  id: string;
  empresa_suspenso?: string | null;
  data_ativacao?: string | null;
  associated_company?: string;
}

type UsersFromApiResponse = {
  result?: CollaboratorType[];
  associatedCompanies?: { name: string, createdAt: Date }[];
  error?: string;
}

export default function useDownloadUsers() {
  const [downloadModalShow, setDownloadModalShow] = useState(false);
  const [selectedFilterDownload, setSelectedFilterDownload] = useState('');

  const { apiCall } = useApiCall();

  const today = new Date().toJSON().slice(0, 10);
  const day = today.split('-')[2];
  const month = today.split('-')[1];
  const year = today.split('-')[0];
  const currentDate = `${day}-${month}-${year}`;

  const downloadUsers = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: usersService.listUsers,
      onStartLoad: () => setDownloadModalShow(false),
      actionAfterResponse: (apiResponse: UsersFromApiResponse) => {
        if (apiResponse.error && apiResponse.error !== 'sem usuarios' && !apiResponse.result) {
          toast.error(`Não foi possível baixar o xlsx dos colaboradores (${apiResponse.error})`);
          return;
        }
        const mappedAllUsers = apiResponse.result!.map((collaborator) => ({
          id: collaborator.id,
          dataAtivacao: collaborator.data_ativacao || 'Nunca ativado',
          // eslint-disable-next-line no-nested-ternary
          status: (collaborator.empresa_suspenso
            ? 'Suspenso'
            : (
              collaborator.data_ativacao
                ? 'Ativo'
                : 'Registrado'
            )),
          // eslint-disable-next-line no-nested-ternary
          codStatus: (collaborator.empresa_suspenso
            ? 2
            : (
              collaborator.data_ativacao
                ? 1
                : 3
            )),
          associatedCompany: collaborator.associated_company ?? 'Não identificada',
        }));

        if (selectedFilterDownload === 'todos') {
          const dataToBeDownloaded = mappedAllUsers.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status TODOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'ativos') {
          const onlyAtivos = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Ativo'
          ));
          const dataToBeDownloaded = onlyAtivos.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status ATIVOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'suspensos') {
          const onlySuspensos = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Suspenso'
          ));
          const dataToBeDownloaded = onlySuspensos.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status SUSPENSOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'registrados') {
          const onlyRegistrados = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Registrado'
          ));
          const dataToBeDownloaded = onlyRegistrados.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status REGISTRADOS ${currentDate}`,
          });
        }
      },
      catchMessage: 'Não foi possível baixar a planilha de colaboradores'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [apiCall, selectedFilterDownload, currentDate]);

  return {
    downloadModalShow,
    setDownloadModalShow,
    selectedFilterDownload,
    setSelectedFilterDownload,
    downloadUsers,
  };
}
